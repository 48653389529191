/**
 * Created by hoa on 23/03/2018.
 */

define(['app', '$window', '$console'], (app, $window, $console) => {

  const athenaBasket = () => {
    const component = {};

    const _config = {
      attrib: {
        skuRemoveID: 'data-remove-sku',
        offerRemoveID: 'data-remove-offer',
        tierRemoveID: 'data-remove-tier',
        isAutoExpanded: 'data-auto-expand',
        freeGiftID: 'data-free-gift-id',
        modalFreeGiftID: 'data-modal-free-gift-id',
        basketFreeGiftID: 'data-basket-free-gift-id',
        hasQualified: 'data-qualifedgift'
      },
      selectors: {
        basketButton: '[data-js-element=athenaBasket_modalTriggerConditions]',
        selectedFreeProducts: '[data-current-selected]',
        qualifiedFreeGiftModal: '[data-open-qualifiedfreegift-modal]',
        continueToCheckoutButton: '[data-continuetocheckout]',
        selectFreeGiftButton: '[data-selectfreegift-button]',
        freeGiftInfoIcon: '[data-js-element=freeProductSelectionB_freeGiftInfoIcon]',
        toggleOpen: '[data-js-element=freeProductSelectionB_qualifiedTierToggle]',
        freeProductItem: '[data-product-item]',
        scrollToFreeProductsMessage: '[data-js-element=discountAlert_scrollToFreeProductsMessage]',
      },
      classNames: {
        showQualifiedFreeGiftModal: 'athenaBasket_qualifiedFreeGiftModal-show',
        closeModalClass: 'athenaBasket_qualifiedFreeGiftModal-close',
        popUpLock: 'js-popup-lock',
        continueToCheckoutButtonButtonClass: 'athenaBasket_qualifiedFreeGiftModalButton-continueToCheckout',
        toggleOpenClass: 'freeProductSelectionB-show'
      }
    };

    const _init = (element, bindFromInit) => {
      component.element = element;
      component.freeProductTiers = component.element.querySelectorAll('[data-product-tier]');
      component.qualifiedFreeGiftModal = component.element.querySelector(component.config.selectors.qualifiedFreeGiftModal);

      if(siteObj.basketDiscountCode) {
        if(siteObj.discountCodeApplied === 'discount.code.applied'){
          window.dataLayer.push({
            event: 'customEvent',
            eventData: {
              eventCategory: 'referral scheme',
              eventAction: 'redeemed',
              eventLabel: siteObj.basketDiscountCode,
              eventPage: ''
            }
          });
        }
      }

      if (component.freeProductTiers.length) {
        component.initialiseFreeProductDependencies(bindFromInit);
        bindFromInit && component.bindAndAttachListeners();
      }
    };

    const _bindRemove = () => {
      const removeItemButtons = component.element.querySelectorAll('[data-js-element=athenaBasket_removeItem]');
      if (!removeItemButtons.length) {
        return;
      }
      Array.from(removeItemButtons).map(element => element.addEventListener('click', component.removeItem, false));
    };

    const _removeItem = (e) => {
      e.preventDefault();
      const skuRemoveID = e.currentTarget.getAttribute(component.config.attrib.skuRemoveID);
      const offerRemoveID = e.currentTarget.getAttribute(component.config.attrib.offerRemoveID);
      const tierRemoveID = e.currentTarget.getAttribute(component.config.attrib.tierRemoveID);
      const freeGiftId =  component.element.querySelector(`[data-gift-id="${skuRemoveID}${offerRemoveID}${tierRemoveID}"]`);
      const onReload = true;
      const isComplex = freeGiftId.classList.contains('freeProductSelectionB_itemProductBlock_complex');

      component.freeProduct.removeUniqueItem(skuRemoveID, offerRemoveID, tierRemoveID, freeGiftId, onReload, isComplex);
    };

    const _attachListeners = () => {
      const basketButtons = component.element.querySelectorAll(component.config.selectors.basketButton);
      const continueToCheckoutButton = component.element.querySelector(component.config.selectors.continueToCheckoutButton);
      const selectFreeGiftButton = component.element.querySelector(component.config.selectors.selectFreeGiftButton);
      const infoIcon = component.element.querySelectorAll(component.config.selectors.freeGiftInfoIcon);
      const toggleEvent = component.element.querySelectorAll(component.config.selectors.toggleOpen);
      const freeProductItem = component.element.querySelectorAll(component.config.selectors.freeProductItem);
      const scrollToFreeProductsMessage = component.element.querySelectorAll(component.config.selectors.scrollToFreeProductsMessage);

      component.qualifiedFreeGiftModal.addEventListener('click', component.modalClose, false);
      Array.from(basketButtons).map(el => el.addEventListener('click', component.modalTriggerConditions));
      continueToCheckoutButton.addEventListener('click', component.continueToCheckout);
      selectFreeGiftButton.addEventListener('click', component.modalScrollToOffer);
      Array.from(toggleEvent).map(el => el.addEventListener('click', component.clickCounter));
      Array.from(freeProductItem).map(el => el.addEventListener('click', component.clickCounter));
      Array.from(scrollToFreeProductsMessage).map(el => el.addEventListener('click', component.discountMessageScrollToOffer));

      if (!infoIcon.length) {
        return;
      }
      Array.from(infoIcon).map(el => el.addEventListener('click', component.clickCounter));
    };

    const _clickCounter = () => {
      component.clickCount++;
    };

    const _modalTriggerConditions = (e) => {
      if (component.freeProductSelections[0]) {
        let hasQualified = component.freeProductSelections[0].getAttribute(component.config.attrib.hasQualified);
        if (hasQualified === 'false') return;
      }

      const selections = component.element.querySelectorAll(component.config.selectors.selectedFreeProducts);
      const arr = Array.from(selections).map(el => parseInt(el.innerHTML));
      const currentSelectionCount = arr.reduce((accumulator,currentValue) => accumulator + currentValue, 0);

      const isAutoExpanded = Array.from(component.freeProductTiers).map(el => el.getAttribute(component.config.attrib.isAutoExpanded));
      let conditions = false;

      if(isAutoExpanded[0] === 'true' || component.clickCount === 0) {
        conditions = true;
      }

      if(component.clickCount > 0) {
        conditions = false;
      }

      if (currentSelectionCount === 0 && conditions && !component.hasAcknowledgedFreeGift) {
        e.preventDefault();
        component.showModal();
      } else {
        app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Clicked to go straight to checkout');
      }
    };

    const _showModal = () => {
      component.prevScrollTop = $window.pageYOffset;

      component.hasAcknowledgedFreeGift = true;
      const openModal = component.qualifiedFreeGiftModal;

      openModal.classList.add(component.config.classNames.showQualifiedFreeGiftModal);
      document.documentElement.classList.add(component.config.classNames.popUpLock);
      document.body.style.top = `${-component.prevScrollTop}px`;
      app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Modal Shown');
    };

    const _hideModal = (element) => {
      const underlay = component.qualifiedFreeGiftModal;
      const closeModalButton = element.classList.contains(component.config.classNames.closeModalClass);

      underlay.classList.remove(component.config.classNames.showQualifiedFreeGiftModal);
      document.documentElement.classList.remove(component.config.classNames.popUpLock);
      document.body.style.top = '';
      app.element.scrollTo(component.prevScrollTop);


      if (element === underlay) {
        app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Clicked on the Modal Underlay');
      }

      if (closeModalButton) {
        app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Clicked on X to Close Modal');
      }
    };

    const _modalClose = (e) => {
      e.preventDefault();

      if (e.target.classList.contains(component.config.classNames.showQualifiedFreeGiftModal)
        || e.target.classList.contains(component.config.classNames.closeModalClass)) {
        component.hideModal(e.target);
      }
    };

    const _continueToCheckout = (e) => {
      $window.location = "/checkoutStart.account";
      app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Clicked to Checkout in modal');
    };

    const _modalScrollToOffer = (e) => {
      component.hideModal(e.target);

      component.scrollToFreeGift();

      app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Clicked to Select Free Gift in modal');
    };

    const _discountMessageScrollToOffer = (e) => {
      const discountMessageOfferId = e.target.getAttribute(component.config.attrib.basketFreeGiftID);

      component.scrollToFreeGift(discountMessageOfferId);

      app.publish('tracking/record', 'athenaBasket_discountAlert', 'Clicked to Select Free Gift in alert');
    };

    const _scrollToFreeGift = (id) => {
      component.hasAcknowledgedFreeGift = true;
      const offer = component.element.querySelector(`[data-free-gift-id="${id}"]`);
      const expandedOffer = offer && offer.querySelector('[data-active-tier="true"]');
      const freeGiftComponent = expandedOffer || component.element.querySelector('[data-active-tier="true"]');

      if (!freeGiftComponent) return $console.warn(`ResponsiveBasket: cannot find [data-free-gift-id="${id}"] or [data-freeProductSelection].`);
      app.element.scrollTo(freeGiftComponent, 200);
      freeGiftComponent.classList.add(component.config.classNames.toggleOpenClass);
    };

    const _initialiseFreeProductDependencies = (bindFromInit) => {
      require(['freeProductTiersB'], function (freeProductTiers) {
        component.freeProduct = freeProductTiers();
        Array.from(component.freeProductTiers).map((el) => {
          component.freeProduct.init(el);
        });

        !bindFromInit && component.bindAndAttachListeners();
      });
    };

    const _bindAndAttachListeners = () => {
      if (component.qualifiedFreeGiftModal) {
        component.hasAcknowledgedFreeGift = false;
        component.clickCount = 0;
        component.attachListeners();
      }

      component.bindRemove();
    };

    component.config = _config;
    component.init = _init;
    component.bindRemove = _bindRemove;
    component.removeItem = _removeItem;
    component.attachListeners = _attachListeners;
    component.clickCounter = _clickCounter;
    component.modalTriggerConditions = _modalTriggerConditions;
    component.showModal = _showModal;
    component.hideModal = _hideModal;
    component.modalClose = _modalClose;
    component.continueToCheckout = _continueToCheckout;
    component.modalScrollToOffer = _modalScrollToOffer;
    component.discountMessageScrollToOffer = _discountMessageScrollToOffer;
    component.scrollToFreeGift = _scrollToFreeGift;
    component.initialiseFreeProductDependencies = _initialiseFreeProductDependencies;
    component.bindAndAttachListeners = _bindAndAttachListeners;

    return component;
  } ;

  return athenaBasket;
});
